@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}

.swiper-slide img {
	display: block;
	/*width: 100%;
	height: 100%;*/
	object-fit: cover;
}

.swiper-pagination-bullet {
	width: 20px !important;
	height: 20px !important;
	text-align: center !important;
	line-height: 20px !important;
	font-size: 12px !important;
	color: #000 !important;
	opacity: 1 !important;
	background: rgba(0, 0, 0, 0.2) !important;
}

.swiper-pagination-bullet-active {
	color: #fff !important;
	background: red !important;
}

.homeSlide1Bg {
	background-image: linear-gradient(135deg, #748fbd 0%, #455073 100%);
}
